

.post-style-1{
    padding: 0!important;
    margin:  0;
    max-width: 100vw;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    width: 100vw;
    height: 80vh;
    
    > .text, > .image{
        grid-column: 1/7;
        grid-row: 1 / 7;
        height: 80vh;

    }
    .image{
        > img{
        transition: all 2s;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    }
    > .text{
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2, p{
            
            box-shadow: 1px 3px 5px 2px #777;
           
            background: rgba(255, 255, 255, .9);
            color: black;
            border-radius: 0;
            padding: 20px;
            text-align: center;
        }
        p{
            text-shadow:none;
        }
    }
}

@media (min-width: 1024px) {

.myPanel{
    &:nth-child(odd){
        > .myText{
            order: 2;
        }
    }
}
}
